import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal, Tabs, Alert } from 'flowbite-react';
import { imageUrls } from '../../utils/constants';
import { number, object, string, ValidationError } from 'yup';
import { AxiosError } from 'axios';
import { Course, Section, Visibility } from '../../types';
import { UploadIcon } from '../../assets/svg-components';
import { ImageUploader } from '../../components';
import { customModalTheme } from '../../custom-themes/customModal';
import { customTabTheme } from '../../custom-themes/customTab';
import { useDeleteFlagStore } from '../../utils/store';
import { ToastContainer, toast } from 'react-toastify';
import { UploadResponse } from '../../components/UploadComponent/types';

const LecturerCourse = () => {
  const { id: courseId } = useParams();
  const setDeletionFlag = useDeleteFlagStore((state: any) => state.setDeletionFlag);
  const dFlag = useDeleteFlagStore((state: any) => state.dFlag);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [deletionModalVisibility, setDeletionModalVisibility] = useState(false);
  const [closable, setClosable] = useState(true);
  const [uploadError, setUploadError] = useState('');
  const [error, setError] = useState('');
  const [sections, setSections] = useState<Section[]>();
  const [sectionValidationErrors, setSectionValidationErrors] = useState<Record<string, string>>();
  const [courseValidationErrors, setCourseValidationErrors] = useState<Record<string, string>>();
  const [editable, setEditable] = useState(false);
  const [imageUploaderState, setImageUploaderState] = useState({
    show: false,
    loading: false,
  });
  const [course, setCourse] = useState<Course>();
  const [courseForm, setCourseForm] = useState<Course>({
    id: '',
    title: '',
    description: '',
    photoUrl: '',
    visibility: '',
    price: 0,
    thumbnailUrl: '',
    sections: [],
  });

  const [sectionForm, setSectionForm] = useState({
    title: '',
    description: '',
  });

  const showErrorToast = () =>
    toast.error('Sorry There Was An Issue Please Try Again Later', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'errorToast',
    });

  const showCourseEditToast = () =>
    toast.success('Course Edited Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'courseEditToast',
    });

  const showSectionCreationToast = () =>
    toast.success('Section Created Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'sectionCreationToast',
    });

  const showSectionDeletionToast = () =>
    toast.success('Section Deleted Successfully', {
      position: 'top-right',
      autoClose: 3000,
      className: 'font-lato text-base font-medium',
      hideProgressBar: true,
      theme: 'light',
      containerId: 'sectionDeletionToast',
    });

  const showImageUploader = () => {
    setImageUploaderState({ ...imageUploaderState, show: true });
  };

  const closeImageUploader = () => {
    setImageUploaderState({ ...imageUploaderState, show: false });
  };

  const validationSectionSchema = object({
    title: string().required('A Title Is Required'),
    description: string()
      .required('A Description Is Required')
      .min(10, 'Description Must Be A Minimun Of 10 Characters')
      .max(100, 'Description Can Only Be A Maximum Of 100 Characters'),
  });

  const validationCourseSchema = object({
    title: string().required('A Title Is Required'),
    price: number().required('A Price Is Required'),
    description: string()
      .required('A Description Is Required')
      .min(10, 'Description Must Be A Minimun Of 10 Characters')
      .max(100, 'Description Can Only Be A Maximum Of 100 Characters'),
  });

  const handleShow = () => setVisible(true);

  const handleClose = () => {
    setSectionForm({ title: '', description: '' });
    setSectionValidationErrors({});
    setUploadError('');
    setVisible(false);
  };

  const handleChange = (e: any) => {
    setSectionForm({
      ...sectionForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleCourseVisibility = (visibility: string) => {
    if (visibility === Visibility.ENROLLED || visibility === Visibility.PUBLIC) {
      setCourseForm((prevForm) => ({
        ...prevForm,
        visibility: Visibility.HIDDEN,
      }));
    } else {
      setCourseForm((prevForm) => ({
        ...prevForm,
        visibility: Visibility.PUBLIC,
      }));
    }
  };

  const handleCourseChange = (e: any) => {
    setCourseForm({ ...courseForm, [e.target.name]: e.target.value });
  };

  const setUploadedImageUrl = (mediaObj: UploadResponse) => {
    setImageUploaderState({ ...imageUploaderState, loading: true });
    setCourseForm({ ...courseForm, photoUrl: mediaObj.url });
    setImageUploaderState({ ...imageUploaderState, loading: false });
  };

  const getCourse = async () => {
    setError('');
    try {
      const res = await axios.get(`/courses/${courseId}`);
      setCourse(res.data);
      setCourseForm(res.data);
      setSections(res.data.sections);
    } catch (err) {
      if (err instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = err.response ? err.response.data.message : defaultErrorMessage;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  const deleteCourse = async () => {
    try {
      await axios.delete(`/courses/${courseId}`);
      setDeletionFlag(true);
      navigate(`/lecturer/courses`);
    } catch (err) {
      setDeletionModalVisibility(false);
      showErrorToast();
    }
  };

  const editCourse = async () => {
    try {
      await validationCourseSchema.validate(courseForm, { abortEarly: false });
      await axios.put(`/courses/${courseId}`, {
        title: courseForm.title,
        description: courseForm.description,
        photoUrl: courseForm.photoUrl,
        price: courseForm.price,
        visibility: courseForm.visibility,
      });
      setEditable(false);
      await getCourse();
      showCourseEditToast();
    } catch (error) {
      if (error instanceof ValidationError) {
        let formErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            formErrors[err.path] = err.message;
          }
        });
        setCourseValidationErrors(formErrors);
      } else {
        showErrorToast();
      }
    }
  };

  const createSection = async () => {
    try {
      await validationSectionSchema.validate(sectionForm, { abortEarly: false });
      await axios.post(`/courses/sections`, { ...sectionForm, courseId });
      getCourse();
      setClosable(true);
      showSectionCreationToast();
      handleClose();
    } catch (error) {
      if (error instanceof ValidationError) {
        let formErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          if (err.path) {
            formErrors[err.path] = err.message;
          }
        });
        setSectionValidationErrors(formErrors);
      } else {
        showErrorToast();
      }
      setClosable(true);
    }
  };

  useEffect(() => {
    if (dFlag) {
      showSectionDeletionToast();
      setDeletionFlag(false);
    }
    getCourse();
  }, []);

  return (
    <div>
      <div className="flex flex-col bg-white rounded shadow-sm page-card-container">
        <div className="flex content-center justify-between m-3 rounded bg-dblue">
          <h1 className="self-center p-8 mb-0 text-2xl font-bold text-white capitalize font-lato">{course?.title}</h1>
          {sections && sections.length >= 1 ? (
            <button className="self-center me-8 custom-primary-section-btn" onClick={handleShow}>
              Create Section
            </button>
          ) : null}
        </div>
        <div className="flex-grow px-6 pb-6">
          <Tabs aria-label="Tabs with underline" style="underline" className="h-full border-0" theme={customTabTheme}>
            <Tabs.Item active title="Course Material">
              {error && (
                <Alert color="failure" className="mb-4">
                  <p className="font-medium font-lato">{error}</p>
                </Alert>
              )}
              {sections && sections.length >= 1 ? (
                <div>
                  {sections.map((section) => (
                    <div key={section._id} className="border-b hover:bg-[#F8F8F8]">
                      <Link
                        to={{
                          pathname: `/lecturer/courses/${courseId}/section/${section._id}`,
                        }}
                        className="text-decoration-none"
                      >
                        <div className="px-6 py-4">
                          <h5 className="text-lg font-semibold capitalize fomt-lato">{section.title}</h5>
                          <p className="mb-1 capitalize font-poppins font-mediumtext-base">{section.description}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col content-center justify-center h-full">
                  <img src={imageUrls.EmptyState} alt="Box" className="mx-auto block pb-2 cursor-pointer h-48" />
                  <p className="mb-1 text-base font-medium text-center capitalize font-lato">No Sections Available</p>
                  <p className="text-sm font-normal text-center capitalize font-lato text-slate-400 mb-6">
                    Sorry, there is no content here at the moment.
                  </p>
                  <div className="flex justify-center">
                    <button
                      className="h-12 text-base rounded font-poppins bg-primary w-36 custom-primary-btn"
                      onClick={handleShow}
                    >
                      Create Section
                    </button>
                  </div>
                </div>
              )}
            </Tabs.Item>
            <Tabs.Item title="Settings">
              <div className="w-full lg:w-1/2">
                <form>
                  <div className="grid gap-4 pb-6 lg:grid-cols-12">
                    <div className="col-span-8">
                      <label htmlFor="courseTitle" className="block pb-4 text-lg font-normal font-lato text-slate-400">
                        Course Title
                      </label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className={`form-input-fields border border-slate-300 ${!editable ? 'bg-gray-200' : ''}`}
                        value={courseForm?.title}
                        onChange={handleCourseChange}
                        required
                        disabled={!editable}
                      />
                      {courseValidationErrors?.title && (
                        <p className="text-base text-red-600 font-lato">{courseValidationErrors.title}</p>
                      )}
                    </div>

                    <div className="col-span-4">
                      <label htmlFor="price" className="block pb-4 text-lg font-normal font-lato text-slate-400">
                        Pricing
                      </label>
                      <input
                        type="text"
                        id="price"
                        name="price"
                        className={`form-input-fields border border-slate-300 ${!editable ? 'bg-gray-200' : ''}`}
                        value={courseForm?.price}
                        onChange={handleCourseChange}
                        required
                        disabled={!editable}
                      />
                      {courseValidationErrors?.price && (
                        <p className="text-base text-red-600 font-lato">{courseValidationErrors.price}</p>
                      )}
                    </div>
                  </div>

                  <label htmlFor="description" className="block pb-4 text-lg font-normal font-lato text-slate-400">
                    Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    value={courseForm?.description}
                    onChange={handleCourseChange}
                    className={`textarea-input-fields border border-slate-300 mb-6 ${!editable ? 'bg-gray-200' : ''}`}
                    disabled={!editable}
                  />
                  {courseValidationErrors?.desription && (
                    <p className="text-base text-red-600 font-lato">{courseValidationErrors.description}</p>
                  )}

                  <label htmlFor="price" className="block pb-4 text-lg font-normal font-lato text-slate-400">
                    Upload Image
                  </label>
                  <div className="relative mb-6" onClick={() => editable && showImageUploader()}>
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                      <UploadIcon />
                    </div>
                    <input
                      type="text"
                      id="photoUrl"
                      name="photoUrl"
                      className={`w-full h-12 rounded border border-slate-300 indent-6 ${
                        !editable ? 'bg-gray-200' : ''
                      }`}
                      value={courseForm.photoUrl}
                      onChange={handleCourseChange}
                      readOnly
                      disabled={!editable}
                    />
                  </div>

                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      defaultChecked={
                        courseForm.visibility === Visibility.ENROLLED || courseForm.visibility === Visibility.PUBLIC
                          ? true
                          : false
                      }
                      className="sr-only peer"
                      onClick={() => handleCourseVisibility(courseForm.visibility)}
                      disabled={!editable}
                    />
                    <div className="relative w-11 h-6 bg-gray-400 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    <span className="text-lg font-medium ms-3 font-lato">Show Course</span>
                  </label>
                </form>
                <div className="pt-6">
                  {editable ? (
                    <div>
                      <button
                        className="h-12 font-bold rounded me-4 custom-primary-btn font-poppins w-36"
                        onClick={() => editCourse()}
                      >
                        Submit
                      </button>
                      <button
                        className="h-12 font-bold rounded custom-secondary-btn font-poppins w-36"
                        onClick={() => setEditable(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="h-12 font-bold text-red-500 border border-red-500 rounded me-4 hover:text-white hover:bg-red-500 font-poppins w-36"
                        onClick={() => setDeletionModalVisibility(true)}
                      >
                        Delete
                      </button>
                      <button
                        className="h-12 font-bold rounded custom-primary-btn font-poppins w-36"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditable(true);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Tabs.Item>
          </Tabs>
        </div>
      </div>

      <Modal show={visible} onClose={handleClose} size="md" position="center" theme={customModalTheme}>
        <Modal.Header className="justify-center p-4 border-0">
          <h3 className="font-bold font-lato">Create New Section</h3>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {uploadError && (
            <Alert color="failure" className="mb-4">
              <p className="font-medium font-lato">{uploadError}</p>
            </Alert>
          )}
          <form>
            <label htmlFor="section_title" className="block pb-2 text-lg font-normal text-gray-400 font-lato">
              Section Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              value={sectionForm.title}
              onChange={handleChange}
              className="border form-input-fields border-slate-500"
              required
            ></input>
            {sectionValidationErrors?.title && (
              <p className="text-base text-red-600 font-lato">{sectionValidationErrors.title}</p>
            )}

            <label htmlFor="description" className="block pt-2 pb-2 text-lg font-normal text-gray-400 font-lato">
              Section Description
            </label>
            <input
              type="text"
              name="description"
              id="description"
              value={sectionForm.description}
              onChange={handleChange}
              className="border form-input-fields border-slate-500"
              required
            ></input>
            {sectionValidationErrors?.description && (
              <p className="text-base text-red-600 font-lato">{sectionValidationErrors.description}</p>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-2 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded custom-secondary-btn font-poppins"
            onClick={handleClose}
            disabled={!closable}
          >
            Close
          </button>
          <button
            className="w-full h-10 text-lg font-medium rounded custom-primary-btn font-poppins"
            onClick={createSection}
          >
            Create Section
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deletionModalVisibility}
        onClose={() => setDeletionModalVisibility(false)}
        size="md"
        position="center"
        theme={customModalTheme}
      >
        <Modal.Body>
          <h1 className="text-2xl font-medium text-center capitalize font-lato">
            Are you sure you want to delete this course ?
          </h1>
        </Modal.Body>
        <Modal.Footer className="pt-2 border-0">
          <button
            className="w-full h-10 text-lg font-medium rounded-md custom-secondary-btn font-poppins"
            onClick={() => setDeletionModalVisibility(false)}
          >
            Cancel
          </button>
          <button
            className="w-full h-10 text-lg font-medium rounded-md custom-primary-btn font-poppins"
            onClick={() => deleteCourse()}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <ImageUploader
        imageName={courseForm.title}
        loading={imageUploaderState.loading}
        show={imageUploaderState.show}
        onHide={closeImageUploader}
        onSuccess={setUploadedImageUrl}
      />
      <ToastContainer containerId="sectionDeletionToast" />
      <ToastContainer containerId="courseEditToast" />
      <ToastContainer containerId="sectionCreationToast" />
      <ToastContainer containerId="errorToast" stacked />
    </div>
  );
};
export default LecturerCourse;
