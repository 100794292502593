import React from 'react';
import { Carousel } from 'flowbite-react';
import { imageUrls } from '../utils/constants';

const FormCarousel = () => {
  return (
    <Carousel className="rounded-none" indicators={false} leftControl="'" rightControl="'">
      <img src={imageUrls.FirstCarousel} />
      <img src={imageUrls.SecondCarousel} />
      <img src={imageUrls.ThirdCarousel} />
    </Carousel>
  );
};

export default FormCarousel;
