import React from 'react';
import { Accordion } from 'flowbite-react';
import { Course, Section } from '../../../types';
import { useMedia } from '../../../components';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const StudentCourseProgressView = ({ courseObject }: { courseObject: Course | undefined }) => {
  const { mediaId, sectionId } = useParams();
  const sectionList = courseObject?.sections || [];
  return (
    <Accordion>
      {sectionList.length > 1 ? (
        sectionList.map((section: Section) => {
          const mediaData = useMedia(section.media.map(({ mediaId }) => mediaId) || []).data;
          return (
            <Accordion.Panel key={section._id}>
              <Accordion.Title>
                <div>
                  <p className="font-lato font-semibold text-base mb-0 capitalize">{section.title}</p>
                </div>
              </Accordion.Title>
              <Accordion.Content className="p-0">
                {mediaData?.map((mediaItem, index) => (
                  <div
                    key={index}
                    className={`border-b border-b-gray-300 py-4 ${
                      mediaItem.id === mediaId ? 'bg-accent2' : 'hover:bg-gray-200'
                    }`}
                  >
                    <Link
                      key={mediaItem.id}
                      to={{
                        pathname: `/student/course/${courseObject?.id}/view/${mediaItem.id}`,
                      }}
                    >
                      <div className="ms-6">
                        <p className="mb-1 font-lato font-semibold text-sm capitalize text-gray-700">
                          {index + 1}. {mediaItem.title}
                        </p>
                        <p className="font-poppins font-medium text-xs text-start ms-3.5">{mediaItem.size}mins</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </Accordion.Content>
            </Accordion.Panel>
          );
        })
      ) : (
        <Accordion.Panel>
          <Accordion.Title className="hidden"></Accordion.Title>
        </Accordion.Panel>
      )}
    </Accordion>
  );
};
export default StudentCourseProgressView;
