import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import axios from '../utils/axios';
import { object, ref, string, ValidationError } from 'yup';
import { Alert } from 'flowbite-react';
import { AxiosError } from 'axios';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { imageUrls } from '../utils/constants';

function NewPassword() {
  const [searchParams] = useSearchParams();
  const [changeDisplay, setChangeDisplay] = useState(true);
  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [toggleCPassword, setToggleCPassword] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>();
  const [submissionErrors, setSubmissionErrors] = useState('');
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
  });
  const resetToken = searchParams.get('token');
  const handleChange = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const validationSchema = object({
    password: string()
      .required('Password Is Required')
      .min(8, 'Password Must Contain At Least 8 Characters')
      .matches(/[!@#$%^&(),.?":{}|<>]/, 'Password Must Contain At Least One Special Character')
      .matches(/[0-9]/, 'Password Must Contain At Least One Number')
      .matches(/[A-Z]/, 'Password Must Contain At Least One UpperCase Letter')
      .matches(/[a-z]/, 'Password Must Contain At Least One LowerCase Letter'),
    confirmPassword: string()
      .required('Confirm Password Is Required')
      .oneOf([ref('password')], 'Passwords Must Match'),
  });

  const PostUserData = async (event: any) => {
    event.preventDefault();
    setSubmissionErrors('');
    try {
      await validationSchema.validate(state, { abortEarly: false });
      await axios.post(`/auth/reset?token=${resetToken}`, {
        password: state.password,
      });
      setChangeDisplay(false);
    } catch (error) {
      if (error instanceof ValidationError) {
        let formErrors: Record<string, string> = {};
        error.inner.forEach((err) => {
          formErrors[err.path!] = err.message;
        });
        setValidationErrors(formErrors);
      } else if (error instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = error.response ? error.response.data.message : defaultErrorMessage;
        setSubmissionErrors(errorMessage);
      } else {
        setSubmissionErrors(error.message);
      }
    }
  };
  return (
    <div className="container mx-auto h-screen flex items-center">
      <div className="w-full">
        {changeDisplay ? (
          <>
            <img src={imageUrls.SilLogo} alt="Studtitlive Logo" className="mx-auto pb-6" />
            <h4 className="font-lato font-bold text-3xl text-center pb-2 capitalize">Reset Password</h4>
            <p className="font-lato text-lg capitalize text-center pb-4">Kindly Fill In Your New Password</p>
            <div className="bg-white rounded drop-shadow-lg lg:w-2/4 mx-auto">
              <div className="p-10">
                {submissionErrors && (
                  <Alert color="failure" className="mb-4">
                    <p className="font-medium font-lato">{submissionErrors}</p>
                  </Alert>
                )}
                <form className="mx-auto w-100">
                  {/* Todo: Password Toggle */}
                  <label htmlFor="password" className="block font-lato text-lg font-normal pb-2">
                    New Password
                  </label>
                  <div className="relative">
                    <div
                      className="absolute inset-y-0 end-0 flex items-center pe-3.5 cursor-pointer"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      {togglePassword ? <EyeSlash size={20} color="#8692A6" /> : <Eye size={20} color="#8692A6" />}
                    </div>
                    <input
                      type={togglePassword ? 'text' : 'password'}
                      id="password"
                      name="password"
                      value={state.password}
                      onChange={handleChange}
                      className="form-input-fields border border-slate-500 pr-10"
                      required
                    ></input>
                    {validationErrors?.password && (
                      <p className="text-red-600 font-lato text-base">{validationErrors.password}</p>
                    )}
                  </div>
                  <label htmlFor="confirmNewPassword" className="block font-lato text-lg font-normal pb-2 pt-4">
                    Confirm Password
                  </label>
                  <div className="relative">
                    <div
                      className="absolute inset-y-0 end-0 flex items-center pe-3.5 cursor-pointer"
                      onClick={() => setToggleCPassword(!toggleCPassword)}
                    >
                      {toggleCPassword ? <EyeSlash size={20} color="#8692A6" /> : <Eye size={20} color="#8692A6" />}
                    </div>
                    <input
                      type={toggleCPassword ? 'text' : 'password'}
                      id="confirmPassword"
                      name="confirmPassword"
                      value={state.confirmPassword}
                      onChange={handleChange}
                      className="form-input-fields border border-slate-500 pr-10"
                      required
                    ></input>
                    {validationErrors?.confirmPassword && (
                      <p className="text-red-600 font-lato text-base">{validationErrors.confirmPassword}</p>
                    )}
                  </div>
                  <button
                    className="custom-primary-btn w-full h-12 rounded font-poppins text-xl font-medium mt-4"
                    onClick={(e) => PostUserData(e)}
                  >
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="bg-white rounded drop-shadow-lg lg:w-2/4 mx-auto">
              <img src={imageUrls.SilLogo} alt="Studtitlive Logo" className="mx-auto pb-6 pt-4" />
              <div className="p-6">
                <h4 className="font-lato font-bold text-3xl text-center pb-2 capitalize">
                  Password Reset Successfully!
                </h4>
                <p className="font-lato font-medium text-lg text-center pb-7">Click The Link Below To Login Again</p>
                <div className="text-center">
                  <Link to="/login" className=" font-poppins text-base font-medium no-underline dark-blue-color ">
                    Back To Login
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default NewPassword;
