import React from 'react';
import { Outlet } from 'react-router-dom';
import SideNav from './SideNav';
import Header from './Header';

function StudentLayout() {
  return (
    <div className="grid lg:grid-cols-12">
      <div className="hidden lg:block lg:col-span-2">
        <SideNav />
      </div>
      <div className="light-bg-grey lg:col-span-10">
        <Header />
        <div className="container px-4 pt-6 mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default StudentLayout;
