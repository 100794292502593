import { removeCookieItem } from './cookies';
import { useUserTypeStore } from './store';
import axios from './axios';

export const formatFileSize = (sizeInBytes: number): string => {
  const kilobyte: number = 1024;
  const megabyte: number = kilobyte * 1024;
  const gigabyte: number = megabyte * 1024;

  if (sizeInBytes < kilobyte) {
    return sizeInBytes + ' B';
  } else if (sizeInBytes < megabyte) {
    return (sizeInBytes / kilobyte).toFixed(2) + ' KB';
  } else if (sizeInBytes < gigabyte) {
    return (sizeInBytes / megabyte).toFixed(2) + ' MB';
  } else {
    return (sizeInBytes / gigabyte).toFixed(2) + ' GB';
  }
};

export const formatDate = (timestamp: string): string => {
  const date = new Date(timestamp);

  const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(date);
  const year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);

  return `${monthName} ${day}, ${year}`;
};

export const logout = async () => {
  const pathName = window.location.pathname;
  const urlContainsLoginOrSignup = /(login|signup)/.test(pathName);
  const res = await axios.post(`/auth/logout`, {});
  if (!urlContainsLoginOrSignup && res.status >= 200 && res.status < 400) {
    removeCookieItem('accessToken', { path: '/' });
    removeCookieItem('refreshToken', { path: '/' });
    useUserTypeStore.getState().setUserType('');
    window.location.href = '/login';
  }
};

export const imageUrlFormatter = (imageUrl: string): string => {
  const prefix = 'http://res.cloudinary.com/studyitlive1/image/upload/v1718572134/imageUploads/';
  const index = imageUrl.indexOf(prefix);
  const cleanedText = imageUrl.slice(index + prefix.length).replace(/[^a-zA-Z.\-_]/g, '');
  return cleanedText;
};
