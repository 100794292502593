export enum MediaType {
  VIDEO = 'Video',
  IMAGE = 'Image',
  DOCUMENT = 'Document',
}

export const imageUrls = {
  studentsReading: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/studentsReading.png',
  boyDesktop: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/boyDesktop2.png',
  BoyPack: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/BoyPack.png',
  GirlTree: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/GirlTree.png',
  BoyLap: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/BoyLap.png',
  Frame1: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/frame1.png',
  TeacherZoom: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/teacherZoom2.png',
  TeacherBoard: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/teacherBoard2.png',
  User1: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/user1.webp',
  User2: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/user2.webp',
  User3: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/user3.webp',
  FooterLogo: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/footerLogo.png',
  HeaderLogo: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/headerLogo.png',
  FirstCarousel: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/firstCarousel.png',
  SecondCarousel: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/secondCarousel.png',
  ThirdCarousel: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/thirdCarousel.png',
  SilLogo: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/sil-logo.png',
  EmptyState: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/Empty_State_Image.png',
  DocumentIcon: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/documentIcon.png',
  TrashIcon: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/trashIcon.png',
  VideoThumbNail: 'https://storage.googleapis.com/studyitlive_website_images/Website_Images/videoThumbnail.png',
};
