import React, { useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Alert } from 'flowbite-react';
import { Course } from '../../types';
import { imageUrls } from '../../utils/constants';

const StudentCourseBrowser = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [error, setError] = useState('');

  const getCourses = async () => {
    try {
      const res = await axios.get(`/courses`);
      setCourses(res.data.results);
    } catch (err) {
      if (err instanceof AxiosError) {
        const defaultErrorMessage: string = 'Sorry We Couldnt Submit Your Request Please Try Again Later';
        const errorMessage: string = err.response ? err.response.data.message : defaultErrorMessage;
        setError(errorMessage);
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <div className="pt-3">
      {error && (
        <Alert color="failure" className="mb-4">
          <p className="font-medium font-lato">{error}</p>
        </Alert>
      )}
      {courses.length >= 1 ? (
        <div className="grid lg:grid-cols-12 gap-4">
          {courses.map((course: Course) => (
            <div key={`course-${course.id}-column`} className="lg:col-span-4 2xl:col-span-3">
              <Link
                to={{
                  pathname: `/student/course/${course.id}`,
                }}
                className="text-black text-decoration-none"
              >
                <div className="bg-white rounded shadow-sm w-full hover:shadow-primary10 hover:shadow-md">
                  <img src={course.photoUrl} alt="" className="p-3 h-52 rounded-lg w-full" />

                  <div className="px-3 mb-0">
                    <p className="font-lato font-medium text-base mb-0.5 capitalize truncate">{course.title}</p>
                    <p className="font-poppins font-medium text-sm capitalize">
                      {course.creator?.firstName} {course.creator?.lastName}
                    </p>
                    <p className="font-lato font-bold text-base mb-0.5 text-inactiveText truncate">N{course.price}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex justify-center content-center">
          <div>
            <img src={imageUrls.EmptyState} alt="Box" className="mx-auto block pb-3 cursor-pointer h-48" />
            <p className="text-center font-lato text-lg font-bold ">Sorry, there is no content here at the moment.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentCourseBrowser;
